if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'waypoints/lib/noframework.waypoints.min.js';

var h1Waypoints = [];

var resizeTimer, width;
var mobile = false, tablet = false, desktop = false;

window.onload = function(){
  setTimeout ( function(){$('body').addClass('window-loaded');}, 1000);
}


$(document).ready(function(){

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){

        $('body').addClass('mobile');

  } else {

  }

  $('.apply-now-CTA').click(function(){
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".mailchimp-signup").offset().top - 100
    }, 2000);
  })

  /*var topsticky = new Waypoint({
    element: $('body')[0],
    handler: function(d) {
      if(d == "down"){
        $('header').addClass('stick');
      } else {
        $('header').removeClass('stick');
      }
    },
    offset: -40,
  })*/

  $('h1').each(function(i, v){
    h1Waypoints.push(
      [
        new Waypoint({
          element: v,
          handler: function(d) {
            if(d == "down"){
              $('h1').removeClass('trigger');
              this.element.classList.add('trigger');
            } else {
              this.element.classList.remove('trigger');
            }
          },
          offset: '30%',
        }),
        new Waypoint({
          element: v,
          handler: function(d) {
            if(d == "up"){
              $('h1').removeClass('trigger');
              this.element.classList.add('trigger');
            }
          },
          offset: function () {
            return (this.context.innerHeight()*.3) - this.adapter.outerHeight()
          },
        })
      ]
    );
  })
})
